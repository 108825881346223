import { MfeFactory, MfeMountParameters } from 'packages/gateway/gateway';
import { Link, createLink } from 'packages/links';
import { ComponentType, createElement, createContext, useContext } from 'react';
import { render } from 'react-dom';
import { ErrorBoundary } from './error-boundary';

export type MandatoryAppProps<Links> = MfeMountParameters & {
  links: Links;
};

interface ReactMfeContextType {
  rootMfePath: Link;
}

const ReactMfeContext = createContext<ReactMfeContextType>({
  rootMfePath: createLink`/non-existent-link`()
});

export const ReactMfeContextProvider = ReactMfeContext.Provider;

export const useRootMfeLink = () => {
  const context = useContext(ReactMfeContext);
  return context;
};

/**
 * Use this to create a MFE implementation to register with a brolly app.
 * Pass in a function that will be called that resolves a promise with props
 * "App" as the root React Component to mount, and prop "createAppLinks" with
 * will be called with a root mount-point link to create a set of links for the
 * app.
 */
export const createReactMfe = <
  LinkInterface extends object,
  LinkImplementation extends LinkInterface,
  Props extends MandatoryAppProps<LinkImplementation>,
  Deps extends Omit<
    Props,
    'mount' | keyof MandatoryAppProps<LinkImplementation>
  > & {
    mount: HTMLElement;
  }
>(
  loader: () => Promise<{
    createAppLinks: (rootLink: Link) => LinkInterface;
    App: ComponentType<Props>;
  }>
) => async () => {
  const { createAppLinks, App } = await loader();

  return ({ mount, ...props }: Deps): MfeFactory<LinkInterface> => ({
    root
  }) => {
    const links = createAppLinks(root);
    return {
      links,
      mount(mountProps) {
        return new Promise<void>((resolve, reject) => {
          render(
            <ErrorBoundary onError={reject}>
              <ReactMfeContextProvider value={{ rootMfePath: root }}>
                <App
                  {...((props as unknown) as Props)}
                  {...mountProps}
                  links={links}
                />
              </ReactMfeContextProvider>
            </ErrorBoundary>,
            mount,
            resolve
          );
        });
      },
      unmount() {
        const Empty = () => null;
        render(<Empty />, mount);
      }
    };
  };
};

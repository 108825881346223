import { Component } from 'react';

export class ErrorBoundary extends Component<
  { onError: (error: any) => void },
  { error?: any }
> {
  state = {};

  componentDidCatch(error: any) {
    this.props.onError(error);
    this.setState({ error });
  }

  render() {
    if ('error' in this.state) {
      return null;
    }

    return this.props.children;
  }
}
